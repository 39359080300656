<template>
  <div v-if="user">
    <!-- Help Guide for updating meal plans -->
    <tour v-if="editTutorial" :steps="firstWay" class="firstWay" :theme="theme"></tour>
    <tour v-if="editTutorial2" class="secondWay" :steps="secondWay" :theme="theme"></tour>
    <tour
      v-if="makeChangesTutorial"
      class="makeChanges"
      :steps="makeChanges"
      :theme="theme"
    ></tour>
    <tour
      v-if="marketplaceTutorial"
      class="marketplacetutorial"
      :steps="marketplaceTutorialData"
      :theme="theme"
    ></tour>

    <!-- GOAL -->
    <div
      class="d-flex align-center mb-5 justify-space-between"
      v-if="
        user &&
        !['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(
          user.role
        )
      "
    >
      <!-- <div v-if="plan" class="d-flex gap-20">
        <custom-logo
          v-if="
            user &&
            [
              'dietitian',
              'personal_trainer',
              'nutritionist',
              'naturopath',
            ].includes(user.role) &&
            !user?.isStudent
          "
          class="step-logo"
          data-step="1"
          :plans="allPlans"
        />

        <v-card @click="videoDialog = true" width="250" height="150" class="pa-5 d-flex flex-column" outlined hover>
          <v-icon size="45" color="accent">mdi-play-circle</v-icon>
          <div class="text-center font-weight-bold">How to Create a Meal Plan</div>
          <div class="text-center caption" :style="{ lineHeight: '1.1' }">If this is the first time you are creating a meal plan, please watch this demo</div>
        </v-card>
      </div> -->

      <div class="d-flex gap-12 mb-5">
        <v-card class="px-5 box-shadow" color="info" dark outlined>
          <v-card-title class="pb-1 caption font-weight-black">
            Daily Energy Goal
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold display-1">
              {{ (goal && customTarget) || displayEnergy | numberFormat }}
              {{ energyUnit }}
            </div>
          </v-card-text>
        </v-card>

        <v-card class="px-5 box-shadow d-none" color="success" outlined dark>
          <v-card-title class="pb-1 caption font-weight-black">
            Ideal Body Weight
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold display-1">
              {{ this.$store.getters["goal/idealWeight"] | numberFormat }} kg
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- <div class="d-flex justify-end align-center mb-2" v-if="plan">
      <v-btn
        @click="showHelpDialog()"
        x-small
        fab
        depressed
        color="info button-help"
      >
        <v-icon dark> mdi-help-circle-outline </v-icon>
      </v-btn>
    </div> -->

    <v-alert v-if="!status.getting && !allFolders?.length" type="info" border="left" text>
      <span class="mr-2">You don't have any client yet.</span>

      <v-btn @click="openCreateFolder()" color="primary" small> Add New Client </v-btn>
    </v-alert>

    <preloader v-if="status.getting" message="tayh dog" />
    <div class="d-flex justify-start align-center mb-5 px-3">
      <div
        class="text-h6 white--text px-3 py-1 rounded-sm"
        :style="{ backgroundColor: isOnMealPlanRoute ? '#97c81f' : '#463f57' }"
      >
        {{ isOnMealPlanRoute ? "Meal Plans" : "Recipe eBook" }}
      </div>
    </div>
    <div class="d-flex justify-start align-center mb-2 px-3" v-if="isOnMealPlanRoute">
      <v-btn @click="copyLink(generatedLink)" color="info" depressed x-small>
        <v-icon small class="mr-1">mdi-content-copy</v-icon>
        Client Questionnaire
      </v-btn>
      <!-- <v-tooltip bottom
            v-if="!isOnMealPlanRoute"
      >
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            :color="isOnMealPlanRoute ? 'white info--text' : 'white primary--text'"
            class="mr-2"
            depressed
            x-small
            @click="linkDialog = true"
          >
            <v-icon small>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>Questionnaire Options</span>
      </v-tooltip> -->
      <v-divider></v-divider>
    </div>
    <div class="d-flex justify-start align-center mb-2 px-3" v-else>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small depressed>
            Client Questionnaires
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="user?.promoData" @click="copyRecipeLink(1, true)">
            <v-list-item-title>Client Questionnaire Link for Promo Recipe eBook 1</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="user?.promoData2" @click="copyRecipeLink(2, true)">
            <v-list-item-title>Client Questionnaire Link for Promo Recipe eBook 2</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="user?.promoData3" @click="copyRecipeLink(3, true)">
            <v-list-item-title>Client Questionnaire Link for Promo Recipe eBook 3</v-list-item-title>
          </v-list-item>
          <v-list-item @click="copyRecipeLink(0, false)">
            <v-list-item-title>Client Questionnaire Link (Customisable Message)</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>
    </div>
    <div class="d-flex gap-1 align-center flex-wrap flex-lg-nowrap" v-if="folders?.length">
      <div class="col-sm-12 col-lg-2">
        <v-card
          class="my-5 box-shadow px-5 pt-5 folder-container arrow-container"
          :class="isOnMealPlanRoute ? 'primary' : 'custom-purple'"
          outlined
        >
          <span class="folder-name">Current Folder:</span>
          <div class="d-flex flex-column mb-3">
            <div class="pa-0">
              <span class="white--text caption">You are currently on folder:</span>
            </div>
            <div class="d-flex gap-1 px-10 flex-column align-center justify-center w-100">
              <img
                src="/svg/FolderIcon.svg"
                class="folder-icon large"
                v-if="isOnMealPlanRoute"
              />
              <img src="/svg/FolderIconGrey.svg" class="folder-icon large" v-else />
              <div class="current-folder-info white--text">
                <div class="caption text-center mb-3">
                  <strong>{{ currentFolder?.client }}</strong>
                </div>
                <div v-show="currentFolder?.id != '1'">
                  <div class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="white info--text"
                          class="mr-2"
                          depressed
                          x-small
                          @click="openCreateFolder(true)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit this Folder</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="white red--text"
                          x-small
                          @click="confirmDeleteFolder = true"
                          depressed
                        >
                          <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete this Folder</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-arrow" :class="isOnMealPlanRoute ? 'green' : 'blue'"></div>
        </v-card>
      </div>
      <div class="col-sm-12 col-lg-10">
        <v-card
          class="my-5 box-shadow pa-5 folder-container"
          outlined
          :class="isOnMealPlanRoute ? 'primary' : 'custom-purple'"
        >
          <span class="folder-name">Your Folders:</span>
          <v-row class="mb-3">
            <div class="d-flex justify-start align-center pa-0">
              <div class="d-flex align-center gap-5">
                <v-text-field
                  v-model="searchFolder"
                  placeholder="Search Folders"
                  hide-details
                  clearable
                  outlined
                  dense
                  background-color="white"
                ></v-text-field>
                <v-btn
                  v-if="
                    $store.getters['user/mealPlans'] > 0 ||
                    $store.getters['user/mealPlans'] == -1
                  "
                  color="white primary--text"
                  class="ml-2"
                  small
                  @click="openCreateFolder()"
                >
                  <v-icon left>mdi-folder-plus</v-icon>
                  Add New Folder
                </v-btn>
              </div>
            </div>
            <v-col cols="12" class="pa-0">
              <span class="white--text caption">Choose a Folder:</span>
            </v-col>
          </v-row>
          <v-slide-group show-arrows v-model="slideModel" center-active>
            <div class="d-flex gap-5 justify-center align-center w-100">
              <v-slide-item
                v-for="p in folders.filter(
                  (p) =>
                    !searchFolder ||
                    p.client
                      .toLowerCase()
                      .includes(searchFolder && searchFolder.toLowerCase())
                )"
                :key="p?.id"
                v-slot="{ toggle }"
              >
                <div
                  :class="{
                    'selected-folder': currentFolder?.id == p?.id,
                    reverse: !isOnMealPlanRoute,
                  }"
                  class="folder-container-info px-5"
                  @click="() => {
                      toggle();
                      changeFolder(p);
                    }
                    "
                >
                  <img src="/svg/FolderIconGrey.svg" class="folder-icon" />
                  <!-- <div
                    v-if="p?.promo"
                    style="
                      position: absolute;
                      top: 45%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <v-icon style="color: #846fb6">mdi-qrcode</v-icon>
                  </div> -->
                  <div style="height: 10px" />
                  <div class="folder-info">
                    <div class="caption folder-info-name">
                      {{ truncate(p.client, { length: 16, omission: ".." }) }}
                    </div>
                  </div>
                </div>
              </v-slide-item>
            </div>
          </v-slide-group>
          <!-- <v-row class="mb-3">
            <v-col cols="12" class="pa-0">
              <span class="white--text caption">Current Folder:</span>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="11">
                  <div class="mr-2" data-step="7">
                    <div
                      :style="{ lineHeight: '1.1' }"
                      class="title font-weight-bold"
                    >
                      <v-btn
                        color="info"
                        class="mr-2"
                        v-if="currentFolder?.id != '1'"
                        x-small
                        icon
                        @click="openCreateFolder(true)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                      <span class="white--text">{{
                        currentFolder?.client
                      }}</span>

                      <v-menu
                        :close-on-content-click="false"
                        v-if="folders?.length"
                        offset-y
                      >
                        <template #activator="{ on }">
                          <v-btn v-on="on" class="ml-2" small icon>
                            <v-icon small>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <v-card class="overflow-hidden">
                          <v-card-title> </v-card-title>
                          <v-card-text
                            :style="{ height: '350px', 'overflow-y': 'scroll' }"
                          >
                            <v-list dense>
                              <v-list-item
                                v-for="p in folders.filter(
                                  (p) =>
                                    (!searchFolder ||
                                      p.client
                                        .toLowerCase()
                                        .includes(
                                          searchFolder &&
                                            searchFolder.toLowerCase()
                                        )) &&
                                    p.id !== currentFolder?.id
                                )"
                                @click="changeFolder(p)"
                                :key="p.id"
                              >
                                <v-list-item-title>{{
                                  p.client
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
                <v-col cols="1" align-self="end">
                  <div class="d-flex justify-end">
                    <v-btn
                      color="white red--text"
                      v-if="currentFolder?.id != '1'"
                      x-small
                      @click="confirmDeleteFolder = true"
                      depressed
                    >
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
        </v-card>
      </div>
    </div>

    <div v-show="!status.getting">
      <div class="px-0 px-lg-5">
        <plans-table
          @addNewPlan="showAddMeal()"
          @addToMarketplace="showMarketplaceDialog()"
          @showRemoveMarketplace="showRemoveMarketplace()"
          @selected="selectPlanFunc"
          @delete="deletePlan()"
          @edit="editPlan"
          @addedNewMeal="selectPlanFunc"
          @clearSelectPlan="clearSelectPlan"
          @manualSelect="manualSelectFirstPlan"
          @roleType="setRoleType"
          :reqPlans="requestedPlans"
          :viewOnly="false"
          :allPlans="allPlans"
          :roleType="roleType"
          :selectedPlan="plan"
          :plans="plans"
          :currentFolder="currentFolder"
          :isOnMealPlanRoute="isOnMealPlanRoute"
          ref="planTable"
        />
      </div>
    </div>
    <!-- ADD TO MARKETPLACE -->
    <add-to-marketplace ref="marketplaceDialog" :plan="plan" />

    <!-- ADD TO MARKETPLACE -->
    <remove-from-marketplace ref="removeMarketplace" :plan="plan" />

    <!-- ADD MEAL -->
    <add-meal @addedNewMeal="selectPlanFunc" ref="mealDialog" />

    <!-- DELETE MEAL PLAN -->
    <confirm-delete
      :showDialog="deletePlanDialog"
      :message="deleteMessage"
      :deleting="status.deletingPlan"
      @cancel="deletePlanDialog = false"
      @confirmed="deleteConfirmed()"
    />

    <!-- VIDEO DIALOG -->
    <v-dialog v-model="videoDialog" width="1200" eager>
      <close-button @click="videoDialog = false" overflow />
      <v-card>
        <!-- <div v-if="user.role == 'member'" style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/718955006?h=089f5a310a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="MEALZEE Members How To.mp4"></iframe></div>
        <div v-else style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/718980969?h=fd0f73b201&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="MEALZEE H&amp;amp;FPs How To.mp4"></iframe></div> -->

        <div
          v-if="user.role == 'personal_trainer' || user.role == 'member'"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <iframe
            src="https://player.vimeo.com/video/832509527?h=0106db4fd3"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            title="MEALZEE How To.mp4"
          ></iframe>
        </div>
        <div v-else style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/830760510?h=0217e39551"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            title="MEALZEE How To.mp4"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="helpDialog" width="320">
      <v-card>
        <v-card-title class="info--text"> Help Guide </v-card-title>
        <v-list dense>
          <v-list-item @click="startIntro()" v-if="user.role !== 'member'">
            <v-list-item-icon>
              <v-icon>mdi-chat-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> How to create a meal plan </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group prepend-icon="mdi-chat-question" no-action>
            <template v-slot:activator>
              <v-list-item-title> How to change a meal plan </v-list-item-title>
            </template>
            <v-list-item @click="startEditTutorialFirstWay()" link>
              <v-list-item-title> First way </v-list-item-title>
            </v-list-item>
            <v-list-item @click="startEditTutorialSecondWay()" link>
              <v-list-item-title> Second way </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item @click="startMakeChanges()">
            <v-list-item-icon>
              <v-icon>mdi-chat-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> How to make changes </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="startMarketplaceTutotrial()">
            <v-list-item-icon>
              <v-icon>mdi-chat-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> How to add to Marketplace </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- warning dialog box -->
    <v-dialog v-model="warningDialog" max-width="390">
      <v-card>
        <v-card-title class="headline">Notice!</v-card-title>
        <v-card-text>
          We recently added the option for nutritionists and naturopaths to contribute
          recipes to MEALZEE. Building a diverse collection of recipes will take time. To
          maximize your meal plan options, consider selecting all Nutrition Professional
          Types
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="warningDialog = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create folder dialog -->
    <v-dialog v-model="createFolder" max-width="1000" @click:outside="closeCreateFolder">
      <close-button @click="closeCreateFolder" overflow />
      <v-card max-width="1000" class="mx-auto box-shadow" outlined>
        <v-card-title :class="$classes.cardTitle">
          <span class="font-weight-bold">
            {{ updateClient ? "Update" : "Create a" }} client
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="pa-0"
          style="min-height: 450px;height: 670px; overflow-x: hidden; overflow-y:auto"
        >
          <v-card class="mb-5 step-energy-dialog" data-step="3" flat>
            <v-card-text>
              <v-form ref="createFolderForm">
                <v-row>
                  <v-col cols="8">
                    <template>
                      <v-text-field
                        v-model="data.client"
                        :rules="[rules.required]"
                        label="Client Name"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model="data.email"
                        label="Client Email"
                        :rules="[rules.email, rules.required]"
                        outlined
                        type="email"
                        dense
                      ></v-text-field>

                      <v-text-field
                        v-model.number="data.age"
                        :rules="[rules.required]"
                        label="Age"
                        type="number"
                        outlined
                        dense
                      ></v-text-field>

                      <v-radio-group
                        :rules="[rules.required]"
                        v-model="data.gender"
                        label="Gender Assigned at Birth: "
                        class="mt-0"
                        dense
                        row
                      >
                        <v-radio label="Male" value="male"></v-radio>
                        <v-radio label="Female" value="female"></v-radio>
                      </v-radio-group>
                    </template>

                    <v-select
                      :label="plan ? 'Primary goal' : 'What is your primary goal?'"
                      :rules="[rules.required]"
                      v-model="data.goal"
                      item-value="value"
                      item-text="text"
                      :items="goals"
                      outlined
                      dense
                    ></v-select>

                    <v-text-field
                      :label="plan ? 'Height (in cm)' : 'Your Height (in cm)'"
                      v-model.number="data.height"
                      :rules="[rules.required]"
                      type="number"
                      suffix="cm"
                      outlined
                      dense
                    ></v-text-field>

                    <v-text-field
                      :label="plan ? 'Weight (in kg)' : 'Your Weight (in kg)'"
                      v-model.number="data.weight"
                      :rules="[rules.required]"
                      type="number"
                      suffix="kg"
                      outlined
                      dense
                    ></v-text-field>

                    <v-text-field
                      :label="plan
                          ? 'Body Mass Index (BMI) is'
                          : 'Your Body Mass Index (BMI) is'
                        "
                      :value="Math.round(bmi)"
                      suffix="kg/m2"
                      outlined
                      readonly
                      dense
                    ></v-text-field>

                    <!-- <v-text-field
                      label="Your Goal Weight (in kg)"
                      v-model="data.goalWeight"
                      :rules="[rules.required]"
                      suffix="kg"
                      outlined
                      dense
                    ></v-text-field> -->

                    <v-select
                      :hint="data.physicalActivityLevel
                          ? data.physicalActivityLevel.description
                          : ''
                        "
                      :persistent-hint="!!data.physicalActivityLevel"
                      v-model="data.physicalActivityLevel"
                      label="Physical Activity Level"
                      :rules="[rules.required]"
                      item-text="name"
                      :items="levels"
                      return-object
                      outlined
                      dense
                    ></v-select>
                  </v-col>

                  <!-- <v-col v-if="!plan" cols="4">
                    <v-card color="info" class="mb-3">
                      <v-card-text class="white--text">
                        <div class="font-weight-bold mb-3">
                          BMI Categories:
                        </div>
                        Underweight =  &lt; 18.5 <br>
                        Normal weight = 18.5 - 24.9 <br>
                        Overweight = 25 - 29.9 <br>
                        Obesity = BMI of 30 or greater <br>
                      </v-card-text>
                    </v-card>

                    <v-card class="d-none" color="success">
                      <v-card-text class="white--text">
                        <div class="font-weight-bold mb-3">
                          Ideal Body Weight
                        </div>
                        <div class="display-1 font-weight-bold">
                          {{ Math.round(idealWeight) }}kg
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-form>
              <div>
                <v-btn
                  :loading="status.creatingFolder"
                  @click="validateForm()"
                  color="accent"
                  class="mr-2"
                  depressed
                >
                  {{ updateClient ? "Update" : "Add" }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DELETE FOLDER DIALOG -->
    <v-dialog max-width="380" v-model="confirmDeleteFolder" persistent>
      <close-button @click="confirmDeleteFolder = false" overflow />
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          <div>Are you sure you want delete to this folder?</div>
          <span class="caption red--text"
            >*This will also delete all the mealplans inside it</span
          >
          <div class="mt-5">
            <v-btn
              @click="deleteFolderFunc"
              :loading="status.deletingFolder"
              color="red white--text"
              class="mr-2"
              depressed
              >Confirm</v-btn
            >

            <v-btn
              :disabled="status.deletingFolder"
              depressed
              @click="confirmDeleteFolder = false"
              >Cancel</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="380" v-model="showWarning">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Note</span>
        </v-card-title>
        <v-card-text>
          <div>
            You have reached your maximum number of meal plans and recipe eBooks for the month. To increase your limit, please click here to upgrade your subscription
          </div>
          <div class="mt-5">
            <v-btn
              @click="$router.push({ name: 'Subscription' })"
              color="primary white--text"
              small
              class="mr-2"
              depressed
              >Go to Subsription page</v-btn
            >
            <v-btn
              @click="showWarning = false"
              color="black--text"
              class="mr-2"
              small
              depressed
              >close</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkDialog" max-width="700">
      <close-button @click="linkDialog = false" overflow />
      <v-card>
        <v-card-text>
          <div class="col-6 px-0">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Allow auto generate
              <v-switch
                v-model="user.allowGenerate"
                color="primary"
                class="ml-3"
              ></v-switch>
            </v-chip>
          </div>
          <v-btn
            @click="updateUserData()"
            v-if="!user?.allowGenerate"
            :loading="userStatus.updating"
            small
            color="primary"
            class="mr-2"
          >
            Save Settings
          </v-btn>
        </v-card-text>
        <v-card-text v-if="user?.allowGenerate">
          <div class="text-h6">Please fill up the form:</div>
          <div style="max-height: 500px; overflow: auto">
            <vue-editor
              v-model="user.recipeplandescription"
              placeholder="cover description"
              class="mb-3"
            />

            <small style="display: block; color: red" v-if="!user.mealplandescription"
              >*please provide a description</small
            >
            <div class="d-flex align-center" style="gap: 0.5rem">
              <v-btn
                @click="updateUserData()"
                :loading="userStatus.updating"
                :disabled="!user.recipeplandescription"
                color="primary"
                class="mr-2"
              >
                Save Settings
              </v-btn>

              <v-btn @click="linkDialog = false" :disabled="userStatus.updating" text
                >cancel</v-btn
              >
              <v-switch
                label="hide instruction page"
                v-model="user.hideinstructions"
                class="mt-0 pt-0"
                hide-details
                dense
              ></v-switch>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog max-width="380" v-model="reqWarning">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">CAUTION</span>
        </v-card-title>
        <v-card-text>
          <div>
            The following Meal Plans for this client has an approved stamp by a
            Dietitian:
            <ul>
              <li v-for="plan in requestedPlans" :key="plan.id">
                {{ plan.name }}
              </li>
            </ul>
            ANY EDITS TO THIS CLIENT WILL REMOVE THE DIETITIAN APPROVED STAMPING
            FOR THESE MEAL PLANS AND WOULD REQUIRE A REVIEW TO HAVE IT APPROVED
            ONCE AGAIN
          </div>
          <div class="mt-5">
            <v-btn
              @click="$router.push({ name: 'Subscription' })"
              color="primary white--text"
              small
              class="mr-2"
              depressed
              >Go to Subsription page</v-btn
            >
            <v-btn
              @click="showWarning = false"
              color="black--text"
              class="mr-2"
              small
              depressed
              >close</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import pluralize from "pluralize";
import _orderBy from "lodash/orderBy";
import { truncate } from "lodash";
import { mapState, mapActions, mapGetters } from "vuex";

// import Tags from '../meals/components/Tags';
import AddMeal from "@/components/AddMealPlan";
import PlansTable from "./components/PlansTable";
// import CustomLogo from './components/CustomLogo.vue';
import AddToMarketplace from "@/components/AddToMarketplace";
import RemoveFromMarketplace from "@/components/RemoveMarketplace";

import IntroJs from "intro.js";
import { VueEditor } from "vue2-editor";

import Tour from "@salamander.be/vue-tour";
import "@salamander.be/vue-tour/dist/vue-tour.css";
import rules from "@/rules";
import { goals, frequencies, levels, patterns, stress } from "@/options";

export default {
  name: "Meals",

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      pluralize,
      nTags: [],
      iTags: [],
      myTags: [],
      goals,
      levels,
      stress,
      patterns,
      truncate,
      frequencies,
      tagView: "meal",
      selectPlan: null,
      videoDialog: false,
      deleteMessage: null,
      addMealDialog: false,
      deletePlanDialog: false,
      editTutorial: false,
      editTutorial2: false,
      makeChangesTutorial: false,
      marketplaceTutorial: false,
      helpDialog: false,
      firstWay: [
        {
          title: "Step 1",
          preview: "/update_meal/lock.jpg",
          description:
            "To change recipes, unlock any recipes you wish to change by clicking the lock icon on the recipe",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 2",
          preview: "/update_meal/auto-generate.jpg",
          description:
            "Click the 'Auto Generate' button. This will automatically replace unwanted recipes with new ones.",
        },
      ],
      secondWay: [
        {
          title: "Step 1",
          preview: "/update_meal/lock.jpg",
          description:
            "To change recipes, unlock any recipes you wish to change by clicking the lock icon on the recipe",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 2",
          preview: "/update_meal/x-button.jpg",
          description: "Press the 'x' button to remove meal",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 3",
          preview: "/update_meal/confirm.jpg",
          description: "A popup will appear, just click on the 'Confirm' button",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 4",
          preview: "/update_meal/find.jpg",
          description: "Click on the 'Select', then choose 'find a meal'",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 5",
          preview: "/update_meal/search.jpg",
          description:
            "A popup will appear, you can then manually search for new recipes.",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 6",
          preview: "/update_meal/all-recipes.jpg",
          description:
            "Click this checkbox to display recipes that are beyond energy (kJ/Cal) range",
        },
        {
          title: "Step 7",
          preview: "/update_meal/select-meal.jpg",
          description: "Click the 'Select' button to add it to your meal plan",
        },
      ],
      makeChanges: [
        {
          title: "Step 1",
          preview: "/make_changes/step1.jpg",
          description: "Change the serve at individual meals if needed",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 2",
          preview: "/make_changes/step2.jpg",
          description:
            "Increase the serves to more than 1, if you wish to drag across to another meal time",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 3",
          preview: "/make_changes/step3.jpg",
          description: "Click and drag the meal to where you want it",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 4",
          preview: "/make_changes/step4.jpg",
          description:
            "If the meal has been pre-prepared, tick the 'MP' box to indicate that the meal has been prepared earlier. This then adds the required ingredients back to the original meal for preparation.",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 5",
          preview: "/make_changes/step5.jpg",
          description: 'click on "VIEW CARD"',
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 6",
          preview: "/make_changes/step6.jpg",
          description:
            "If the meal is pre-prepared meal, the recipe card will not have a method or ingredient quantities. Instead, these will be on the recipe card at the meal where the recipe was originally prepared.",
        },
      ],
      marketplaceTutorialData: [
        {
          title: "Step 1",
          preview: "/marketplace/step1.jpg",
          description:
            "Once you're happy with the meal plan, you can click on ADD TO MARKETPLACE",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 2",
          preview: "/marketplace/step2.jpg",
          description: "Rename the meal plan to describe what the meal plan is",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 3",
          preview: "/marketplace/step3.jpg",
          description: "Add a further brief descripiton explaining the meal plan",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 4",
          preview: "/marketplace/step4.jpg",
          description: "Click on ADD TO MARKETPLACE",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 5",
          preview: "/marketplace/step5.jpg",
          description:
            "Go to the Marketplace tab and refresh your screen to see your meal plan. Now others can benefit from your creation.",
          next_cta: "Next Step",
          prev_cta: "Previous Step",
        },
        {
          title: "Step 6",
          preview: "/marketplace/step6.jpg",
          description:
            "Check out the other meal plans in the marketplace and copy them to your account to modify or use as they are. Click the 3 dots",
        },
        {
          title: "Step 7",
          preview: "/marketplace/step7.jpg",
          description: "Click on Copy Mealplan",
        },
        {
          title: "Step 8",
          preview: "/marketplace/step8.jpg",
          description: "Alternatively, click on MORE INFO",
        },
        {
          title: "Step 9",
          preview: "/marketplace/step9.jpg",
          description: "Click on COPY MEALPLAN. It's now copied to your account.",
        },
      ],
      theme: {
        color: "#009de0",
        radius: "2px",
        top: "30px",
      },
      roleType: "all",
      warningDialog: false,
      searchFolder: "",
      createFolder: false,
      updateClient: false,
      showWarning: false,
      confirmDeleteFolder: false,
      linkDialog: false,
      plan: null,
      slideModel: null,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    AddMeal,
    PlansTable,
    Tour,
    VueEditor,
    AddToMarketplace,
    RemoveFromMarketplace,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      goal: (state) => state.goal.goal,
      user: (state) => state.user.user,
      tags: (state) => state.tags.tags,
      allPlans: (state) => state.mealplans.plans,
      data: (state) => state.goal.data,
      status: (state) => state.mealplans.status,
      userStatus: (state) => state.user.status,
      allFolders: (state) => state.mealplans.folders,
      currentFolder: (state) => state.mealplans.currentFolder,
      nutritionTags: (state) => state.nutritiontags.tags,
    }),

    ...mapGetters("goal", ["bmi"]),

    subscription: function () {
      let active = this.$store.getters["subscription/active"];
      return active;
    },

    // plan: function (p) {
    //   if (p)
    //     return this.plans?.length
    //       ? this.plans.find((plan) => plan.id == this.selectPlan)
    //       : null;
    //   else return null;
    // },

    confPlans() {
      return _orderBy(this.plans, "createdAt", "asc");
    },

    plans: function () {
      if (this.currentFolder?.id == "1")
        return this.allPlans.filter((p) => !this.folderIds.includes(p?.folderId));

      if (this.currentFolder?.id == "2")
        return this.allPlans.filter((p) => p.folderId == "2");

      if (!this.isOnMealPlanRoute) {
        return this.allPlans?.length
          ? this.allPlans.filter(
            (p) => this.currentFolder?.mealplans?.includes(p.id) && p?.recipeplan
          )
          : null;
      }

      return this.allPlans?.length
        ? this.allPlans.filter(
          (p) => this.currentFolder?.mealplans?.includes(p.id) && !p?.recipeplan
        )
        : null;
    },

    displayEnergy() {
      return this.user.energyUnit === "kj"
        ? this.$store.getters["goal/sktbg"]
        : this.$store.getters["goal/sctbg"];
    },

    customTarget() {
      return this.user.energyUnit === "kj"
        ? this.goal.customTarget
        : this.goal.customTarget / 4.18;
    },

    energyUnit() {
      return this.user.energyUnit === "kj" ? "kJ" : "cal";
    },
    folders(val) {
      if (val)
        return [
          ...this.allFolders,
          { client: "Marketplace", id: "2" },
          { client: "Others", id: "1" },
        ];
      else return null;
    },
    folderIds() {
      const ids = this.allFolders.map((f) => f.id);

      return ["2", ...ids];
    },

    generatedLink() {
      // return `${window.origin}/form/${this.removeSpace(this.user.firstName + this.user.lastName)}?i=${this.user.id}`
      return `https://mealguide.pro/form/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}`;
    },

    generatedRecipeLink() {
      // return `${window.origin}/recipeform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}`;
      return `https://mealguide.pro/recipeform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}`;
    },

    generatedLinkPromo() {
      // return `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}`;

      return `http://mealguide.pro/form/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}`;
    },
    requestedPlans() {
      return this.allPlans.filter((p) => p?.requestStatus);
    },

    isOnMealPlanRoute() {
      return this.$route.name == "MealPlans";
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    plans: function (val) {
      if (val?.length) {
        this.$store.commit("mealplans/clearAllMeals");
        this.getAllMeals(val);
      }
      // this.selectPlanFunc(null, true);
    },

    allPlans: function (val) {
      if (!this.allFolders?.length && val?.length)
        this.$store.commit("mealplans/selectFolder", {
          client: "Others",
          id: "1",
        });

      // if (
      //   val?.length >= this.$store.getters["user/mealPlans"] &&
      //   this.$store.getters["user/mealPlans"] != -1
      // )
      //   this.showWarning = true;
      if (!this.$store.getters['subscriptions/subscribed'])
        this.showWarning = true;
    },
    $route(to, from) {
      // this.selectPlanFunc()

      if (from.name != this.$route.name) {
        this.selectPlan = this.confPlans[this.confPlans.length - 1].id;
        this.plan = this.confPlans[this.confPlans.length - 1];
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("mealplans", [
      "getPlansByFolder",
      "deleteFolder",
      "updateFolder",
      "getAllMeals",
      "getFolders",
      "getFolder",
      "addFolder",
      "getPlans",
      "getPlan",
    ]),

    ...mapActions("user", ["updateTags", "updateUserData"]),

    ...mapActions("subscriptions", [
      "getSubscriptions",
      "getPayments",
      "getTrialingPayments",
    ]),

    ...mapActions("pricing", ["getStampPrices"]),

    showAddMeal() {
      this.$refs.mealDialog.showDialog();
    },

    deletePlan() {
      this.deletePlanDialog = true;
      this.deleteMessage = `Delete plan "${this.plan.name}"?`;
    },

    deleteConfirmed() {
      Promise.resolve(this.$store.dispatch("mealplans/deletePlan", this.plan)).then(
        () => {
          this.selectPlan = null;
          this.deletePlanDialog = false;
          this.plan = null;
          this.updateMealplanQuery(this.currentFolder.id, "", this.slideModel);
          this.getUserPlans(this.currentFolder.id);
        }
      );
    },

    showMarketplaceDialog() {
      this.$refs.marketplaceDialog.showDialog();
    },

    showRemoveMarketplace() {
      this.$refs.removeMarketplace.showDialog();
    },

    getUserPlans(folderId) {
      if(folderId == '1')
        return Promise.resolve(this.getPlans(folderId)).then(() => {
          this.selectPlanFunc(null, true);
        });


      Promise.resolve(this.getPlansByFolder(folderId)).then(() => {
        this.selectPlanFunc(null, true);
      });
    },

    /*
     * TODO: Check why this runs twice
     */
    selectPlanFunc(plan = null, firstLoad = false, planSlide = 0) {
      let id = null;

      if (firstLoad && this.$route.query?.mealplan) {
        let routeId = this.$route.query?.mealplan;

        if (this.plans.find((p) => p.id == routeId)) id = this.$route.query?.mealplan;
        else id = this.plans[0].id;
      } else if (!plan && !this.selectPlan && this.plans?.length) {
        if (this.plans[0]?.fromForm && !this.plans[0]?.mealsLoaded) id = this.plans[1].id;
        else id = this.plans[0].id;
      } else if (plan) {
        id = plan.id;
      }


      if (id)
        this.selectPlan = id;

      if (id && !firstLoad) this.updateMealplanQuery(this.currentFolder.id, id, this.slideModel, planSlide);

      this.plan = this.plans?.length
        ? this.plans.find((plan) => plan.id == this.selectPlan)
        : null;

      // this.firstTimeUser();
    },

    editPlan(plan) {
      this.$store.commit("mealplans/setData", plan);
      this.addMealDialog = true;
    },

    getMyTags() {
      Promise.all([this.$store.dispatch("tags/getMyTags")]).then((response) => {
        if (response[0]) {
          let data = response[0];
          this.myTags = data.tags;
        }
      });
    },
    startIntro() {
      this.helpDialog = false;
      const introJs = IntroJs();

      const isSubscribed =
        this.plans?.length < this.$store.getters["user/mealPlans"] ||
        this.$store.getters["user/mealPlans"] == -1;

      /**
       * TODO: Find a way to optimize this
       */

      const stepsWithGoal1 = [
        {
          title: "Step 1",
          element: document.querySelector(".step-logo"),
          intro: "If this is your first time, add your logo and brand colour",
        },
        {
          title: "Step 2",
          element: document.querySelector(".step-energy-btn"),
          intro:
            "Click on the 'Add Meal Plan Details' to add your client details and eating pattern",
        },
        {
          title: "Step 3",
          element: document.querySelector(".step-energy-dialog"),
          intro:
            "Fill out the required fields, then proceed to the second slide by clicking the continue button. Then click on update button once all done",
        },
        {
          title: "Step 4",
          element: document.querySelector(".step-meal-tags"),
          intro:
            "If needed, choose any meal tags, nutrition tags, and any allergies, intolerances and or dislikes your client may have",
        },
        {
          title: "Step 5",
          element: document.querySelector(".step-meal-servings"),
          intro:
            "This column is where you can set your serving number for each meal time across the week (Breakfast, Lunch, Dinner and Snacks)",
        },
        {
          title: "Step 6",
          element: document.querySelector(".step-serving-number"),
          intro:
            "You can change this on individual days to reflect how many meal serves may vary throughout the week",
        },
        {
          title: "Step 7",
          element: document.querySelector(".step-generate-btn"),
          intro:
            "Lastly, generate the meal plan by clicking the 'Auto Generate'. After that you may review your selections and nutrition info",
        },
      ];

      const stepsWithNoGoal1 = [
        {
          title: "Step 1",
          element: document.querySelector(".step-logo"),
          intro: "If this is your first time, add your logo and brand colour",
        },
        {
          title: "Step 2",
          element: document.querySelector(".step-energy-btn"),
          intro:
            "Click on the 'Add Meal Plan Details' to add your client details and eating pattern",
        },
        {
          title: "Step 3",
          element: document.querySelector(".step-energy-dialog"),
          intro:
            "Fill out the required fields, then proceed to the second slide by clicking the continue button. Then click on update button once all done",
        },
        {
          title: "Step 4",
          element: document.querySelector(".step-meal-tags"),
          intro:
            "If needed, choose any meal tags, nutrition tags, and any allergies, intolerances and or dislikes your client may have",
        },
        {
          title: "Step 5",
          element: document.querySelector(".step-generate-btn"),
          intro:
            "Lastly, generate the meal plan by clicking the 'Auto Generate'. After that you may review your selections and nutrition info",
        },
      ];

      const stepsWithGoal2 = [
        {
          title: "Step 1",
          element: document.querySelector(".step-logo"),
          intro: "If this is your first time, add your logo and brand colour",
        },
        {
          title: "Step 2",
          element: document.querySelector(".step-add-new"),
          intro: "Click the 'Add New' button.",
        },
        {
          title: "Step 3",
          element: document.querySelector(".step-energy-btn"),
          intro:
            "Click on the 'Add Meal Plan Details' to add your client details and eating pattern",
        },
        {
          title: "Step 4",
          element: document.querySelector(".step-energy-dialog"),
          intro:
            "Fill out the required fields, then proceed to the second slide by clicking the continue button. Then click on update button once all done",
        },
        {
          title: "Step 5",
          element: document.querySelector(".step-meal-tags"),
          intro:
            "If needed, choose any meal tags, nutrition tags, and any allergies, intolerances and or dislikes your client may have",
        },
        {
          title: "Step 6",
          element: document.querySelector(".step-meal-servings"),
          intro:
            "This column is where you can set your serving number for each meal time across the week (Breakfast, Lunch, Dinner and Snacks)",
        },
        {
          title: "Step 7",
          element: document.querySelector(".step-serving-number"),
          intro:
            "You can change this on individual days to reflect how many meal serves may vary throughout the week",
        },
        {
          title: "Step 8",
          element: document.querySelector(".step-generate-btn"),
          intro:
            "Lastly, generate the meal plan by clicking the 'Auto Generate'. After that you may review your selections and nutrition info",
        },
      ];

      const stepsWithNoGoal2 = [
        {
          title: "Step 1",
          element: document.querySelector(".step-logo"),
          intro: "If this is your first time, add your logo and brand colour",
        },
        {
          title: "Step 2",
          element: document.querySelector(".step-add-new"),
          intro: "Click the 'Add New' button.",
        },
        {
          title: "Step 3",
          element: document.querySelector(".step-energy-btn"),
          intro:
            "Click on the 'Add Meal Plan Details' to add your client details and eating pattern",
        },
        {
          title: "Step 4",
          element: document.querySelector(".step-energy-dialog"),
          intro:
            "Fill out the required fields, then proceed to the second slide by clicking the continue button. Then click on update button once all done",
        },
        {
          title: "Step 5",
          element: document.querySelector(".step-meal-tags"),
          intro:
            "If needed, choose any meal tags, nutrition tags, and any allergies, intolerances and or dislikes your client may have",
        },
        {
          title: "Step 6",
          element: document.querySelector(".step-generate-btn"),
          intro:
            "Lastly, generate the meal plan by clicking the 'Auto Generate'. After that you may review your selections and nutrition info",
        },
      ];

      introJs
        .setOptions({
          steps: !isSubscribed
            ? this.goal
              ? stepsWithGoal1
              : stepsWithNoGoal1
            : this.goal
              ? stepsWithGoal2
              : stepsWithNoGoal2,
        })
        .onbeforechange(async (target) => {
          const step = target.getAttribute("data-step");
          if (step === null || step === "3") {
            this.$refs.planTable.showCalculator();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 800);
            });
          }
          if (step === "4" || step === "2") {
            this.$refs.planTable.closeCalculator();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 300);
            });
          }
        })
        .start();
    },
    firstTimeUser() {
      const introJs = IntroJs();

      setTimeout(() => {
        introJs
          .setOptions({
            steps: [
              {
                title: "Guide",
                element: document.querySelector(".button-help"),
                intro: "Click on this button to show the Guide lists",
              },
            ],
            disableInteraction: true,
            doneLabel: "Got it",
            showBullets: false,
            showProgress: false,
            dontShowAgain: true,
          })
          .start();
      }, 300);
    },
    startEditTutorialFirstWay() {
      this.helpDialog = false;
      this.editTutorial = !this.editTutorial;

      setTimeout(() => {
        document
          .querySelector(".firstWay > .tour-preview > .step > .close")
          .addEventListener("click", () => {
            this.editTutorial = false;
          });
      }, 1000);
    },
    startEditTutorialSecondWay() {
      this.helpDialog = false;
      this.editTutorial2 = !this.editTutorial2;

      setTimeout(() => {
        document
          .querySelector(".secondWay > .tour-preview > .step > .close")
          .addEventListener("click", () => {
            this.editTutorial2 = false;
          });
      }, 1000);
    },
    startMakeChanges() {
      this.helpDialog = false;
      this.makeChangesTutorial = !this.makeChangesTutorial;

      setTimeout(() => {
        document
          .querySelector(".makeChanges > .tour-preview > .step > .close")
          .addEventListener("click", () => {
            this.makeChangesTutorial = !this.makeChangesTutorial;
          });
      }, 1000);
    },
    startMarketplaceTutotrial() {
      this.helpDialog = false;
      this.marketplaceTutorial = !this.marketplaceTutorial;

      setTimeout(() => {
        document
          .querySelector(".markeplacetutorial > .tour-preview > .step > .close")
          .addEventListener("click", () => {
            this.marketplaceTutorial = !this.marketplaceTutorial;
          });
      }, 1000);
    },
    showHelpDialog() {
      this.helpDialog = true;
    },

    setRoleType(type) {
      this.roleType = type;

      if (type == "nutritionist" || type == "naturopath") this.warningDialog = true;
    },

    validateForm() {
      if (this.$refs.createFolderForm.validate()) {
        let task;

        if (this.updateClient)
          task = Promise.resolve(
            this.updateFolder({
              ...this.data,
              id: this.currentFolder.id,
              mealplans: this.currentFolder.mealplans,
            })
          );
        else task = Promise.resolve(this.addFolder(this.data));

        task.then(() => {
          this.createFolder = false;

          if (this.goal) this.$store.dispatch("goal/update", this.data);
        });
      }
    },
    changeFolder(f) {
      this.$store.commit("mealplans/selectFolder", f);
      this.updateMealplanQuery(f.id, "", this.slideModel);

      if (f.id != "1") this.$store.commit("goal/setGoalData", f);

      // if (this.plans?.length) {
      //   this.selectPlan = this.plans[0].id;
      // } else {
      //   this.updateFolderQuery(f.id);
      // }

      if (!this.plans?.length) {
        this.updateFolderQuery(f.id, this.slideModel);
      }

      // this.selectPlanFunc();

      this.clearSelectPlan()

      this.getUserPlans(f.id);

    },

    openCreateFolder(update = false) {
      this.createFolder = true;

      setTimeout(() => {
        if (!update) {
          this.$store.commit("goal/setData", { mainMeals: [] });
          this.$refs.createFolderForm.reset();
        } else this.updateClient = true;
      }, 100);
    },
    closeCreateFolder() {
      this.createFolder = false;
      this.updateClient = false;
      this.$store.commit("goal/setGoalData", this.currentFolder);
    },
    openAddMealPlan() {
      this.$refs.planTable.showCalculator();
    },
    clearSelectPlan() {
      this.selectPlan = "";
    },
    manualSelectFirstPlan(id) {
      this.selectPlan = id;
      this.selectPlanFunc();
    },
    deleteFolderFunc() {
      Promise.resolve(this.deleteFolder(this.plans)).then(() => {
        this.confirmDeleteFolder = false;
        this.getFolders();

        setTimeout(() => {
          this.changeFolder(this.folders[0]);
        }, 800);
        // Promise.resolve(this.getUserPlans())
        // .then(() => {
        //   console.log(this.plans)
        //   this.manualSelectFirstPlan()
        // })
      });
    },

    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },

    copyLink(link) {
      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },

    copyRecipeLink(promoPage, autoGen) {
      const link = `https://mealguide.pro/promoform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      // const link =  `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },

    updateFolderQuery(id, slide = null) {
      let query = this.$route.query;
      this.$router.push({ query: { ...query, folder: id, slide } }).catch(() => { });
    },

    updateMealplanQuery(folderId, id, slideModel = null, planSlideModel = null) {

      if (folderId == '1' || folderId == '2') {
        setTimeout(() => {
          this.$router
            .push({ query: { } })
            .catch(() => { });
        }, 300);

        return
      }

      let mealplan = { mealplan: id };
      let folder = { folder: folderId };
      let slide = { slide: slideModel };
      let planSlide = { planSlide: planSlideModel };
      let query = this.$route.query;

      setTimeout(() => {
        this.$router
          .push({ query: { ...query, ...folder, ...mealplan, ...slide, ...planSlide } })
          .catch(() => { });
      }, 300);
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.getters["subscriptions/subscribed"]) {
      this.$root.$emit("showSubscribeDialog");
      return;
    }

    if (this.user && this.user.role == "member" && !this.goal)
      this.$store.dispatch("goal/getGoal");
    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch("tags/getTags");
    this.$store.dispatch("nutritiontags/getTags");
    this.getMyTags();

    if (this.$route.query.p) {
      Promise.resolve(this.getPlan(this.$route.query.p)).then(() => {
        this.selectPlan = this.$route.query.p;
      });
    }

    this.$store.dispatch("pricing/getPlans");

    this.getSubscriptions();
    this.getStampPrices();

    if (this.$route.query?.slide) {
      this.slideModel = +this.$route.query?.slide
    }
  },

  created() {
    this.getPayments();
    this.getTrialingPayments();
    Promise.resolve(this.getFolders()).then(() => {
      this.getUserPlans(this.currentFolder.id);
      this.$store.commit("goal/setGoalData", this.currentFolder);
    });

    if (this.user.mealSlots != -1 && !this.$store.getters['subscriptions/active']) {

      this.$store.dispatch('user/updateUserField', {
        field: 'mealSlots',
        value: 0,
        silent: true,
      });

      this.$store.dispatch('user/updateUserField', {
        field: 'downloadCredits',
        value: 0,
        silent: true,
      });

      this.$store.dispatch('user/updateUserField', {
        field: 'showSubscriptionEndDate',
        value: false,
        silent: true,
      });
    }
  },
};
</script>

<style lang="scss">
.align-start {
  .v-select__slot {
    align-items: flex-start;
  }
}

.folder-container {
  position: relative;
}

.folder-name {
  position: absolute;
  top: -25px;
  left: 0px;
  background-color: #463f57;
  color: white;
  padding: 0 1rem;
}

.folder-container-info {
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #463f57;
  }

  &.selected-folder {
    background-color: #463f57;
  }
}

.folder-container-info.reverse {
  &:hover {
    background-color: #97c81f;
  }

  &.selected-folder {
    background-color: #97c81f;
  }
}

.folder-icon {
  width: 65px;
  height: 65px;

  &.large {
    width: 100px;
    height: 100px;
  }
}

.folder-info {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.folder-info-name {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-container {
  position: relative;
}

.bottom-arrow:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 25px solid #97c81f;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.bottom-arrow.blue:after {
  border-top: 25px solid #463f57;
}

.custom-purple {
  background-color: #463f57 !important;
  border-color: #463f57 !important;
}
</style>
